import React, { useState, useEffect } from 'react';
import * as QueryString from 'query-string';
import FadeIn from 'react-fade-in';
import { auth } from '../../firebase/firebaseConfig';
import FadeInLoader from '../customFadeInLoader/fade-in-loader.component';
import CustomButton from '../custom-button/custom-button.component';
import { Colors } from '../../utils/colors';
import { navigate } from 'gatsby';

const VerifyEmail = ({ location, ...otherProps }) => {

	let parsedQuery = QueryString.parse(location.search);

	const [verifiedEmail, setVerifiedEmail] = useState(false)

	const [showDashboard, setShowDashboard] = useState(false)

	const mode = parsedQuery.mode;

	const actionCode = parsedQuery.oobCode;

	useEffect(() => {

		verifyuserEmail()
	
	}, [])


	const verifyuserEmail = async () => {
		try {

			console.log("Verify Current user");

			if (mode !== 'verifyEmail') return alert("Error verifying email");

			await auth.applyActionCode(actionCode)

			setVerifiedEmail(true)

			setTimeout(() => {
				setShowDashboard(true)
			},1000)

			console.log("Verify Current user after actionCode");

			console.log(auth.currentUser)
		} catch (error) {
			console.log(error)
			alert("Looks like we were not able to verify your email :(")
		}
	}
	
	
	return ( 
		<>
		{
			showDashboard ?
			<FadeIn>
				<CustomButton width='200px' background={Colors.primary} color='white' onClick={() => navigate('/admin/dashboard')}>
					Go to Dashboard Login
				</CustomButton>
			</FadeIn>
			:
			<FadeInLoader text={verifiedEmail ? 'Email Verified': 'Verifying Email'} show={verifiedEmail} /> 
			
		}
		</>
	 );
}
 
export default VerifyEmail;