import React from "react"
import { Router } from "@reach/router"
import Layout from '../components/layout';
import Signup from '../components/clientPages/signup.page';
import AcceptInvite from '../components/clientPages/acceptInvite.page';
import VerifyEmail from "../components/clientPages/verifyEmail.page";
import Default from "../components/clientPages/default.page";

const App = (props) => {
  return (
    <Layout>
      <Router basepath="/app">
        <Signup path="/signup" />
				<AcceptInvite path="/invite/:inviteId" />
				<VerifyEmail path="/verifyEmail" />
        {/* <Default path="/" /> */}
      </Router>
    </Layout>
  )
}
export default App