import React from 'react';
import styled from 'styled-components';

const SignUp = () => {
	return ( 
		<Container>
			<Title>Sign Up Page</Title>
		</Container>
	 );
}
 
export default SignUp;

const Container = styled.div`
display: flex;
flex: 1;
justify-content: center;
align-items: center;
`;

const Title = styled.h1`
font-size: 50px;
`;