import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FadeIn from "react-fade-in";
import { Colors } from '../../utils/colors';
import CustomButton from '../custom-button/custom-button.component';
import FadeInLoader from '../customFadeInLoader/fade-in-loader.component';
import { getInviteRequest, auth, firestore } from '../../firebase/firebaseConfig';
import { navigate } from 'gatsby';

const AcceptInvite = (props) => {
	const { inviteId } = props;
	const [loading, setLoading] = useState(false);
	const [account, setAccount] = useState({email: '', password: ''});
	const [doneFetchingUserDetails, setdoneFetchingUserDetails] = useState(false);
	const [doneSettingUp, setDoneSettingUp] = useState(false);
	const [doneCreatingUserInfo, setDoneCreatingUserInfo] = useState(false);
	const [doneSendingVerification, setdoneSendingVerification] = useState(false);
	const [submitClicked, setSubmitClicked] = useState(false);

	const handleInput = (e) => {
		let {name, value} = e.target

		setAccount({...account, [name]: value})
	}

	const handleSubmit = async (e) => {

		e.preventDefault();

		setSubmitClicked(true);

		try {

				let userDetails = await getUserDetails(inviteId);

				if(userDetails.email.toLowerCase() !== account.email.toLowerCase()) throw "Sorry your invitation is invalid";

				//await signUpUser("jonathan@wheredpump.com", "test247");
				let userRef = await signUpUser(account.email, account.password);

				//setdoneFetchingUserDetails(true)

				//setDoneSettingUp(true);

				await creatUserInfoRecord(userRef.user.uid, userDetails);

				setDoneCreatingUserInfo(true)
		
				await sendVerificationEmail();

				setTimeout(() => {
					navigate('/verifyEmailMessage')
				}, 1200);


		} catch (error) {

			console.log(error);

			alert(error);

		}

	}

	const getUserDetails = async (id) => {

		const userDetailsSnapShot = await getInviteRequest(id);

		if (!userDetailsSnapShot.exists) return console.log('User Not found');

		setTimeout(() => {
			setdoneFetchingUserDetails(true)
		},800);
	

		return userDetailsSnapShot.data();

	}

	const signUpUser = async (email, password) => {

			try {

				let userAuth = await auth.createUserWithEmailAndPassword(email, password)
			
				setTimeout(() => {
					setDoneSettingUp(true);
				}, 800);
	
				return userAuth;
			} catch (err) {

				throw "error signing up"

			}

	}

	const creatUserInfoRecord = async (uid, userDetails) => {
		let userInfoDocRef = firestore.collection('UserInfo').doc(uid)

		let snapshot = await userInfoDocRef.get();

		if(!snapshot.exists) {
			const createdAt = new Date();
			try {

				await userInfoDocRef.set({
					...userDetails,
					createdAt
				})

			} catch (error) {
				console.log("Error creating user Info");
				console.log(error)
				return Promise.reject();
			}
		}

		return Promise.resolve()
	}

	const sendVerificationEmail = async () => {

		await auth.currentUser.sendEmailVerification()

		setTimeout(() => {
			setdoneSendingVerification(true)
		}, 800);

	}

	return ( 
		<Container>
			
			{
				!submitClicked ?
				<CreatePasswordWrapper>
					<Title>Accept Invite</Title>
					<SubTitle>Create a password for your account</SubTitle>
					<Form onSubmit={handleSubmit}>
						<InputFieldContainer>


						<InputField 
								type='text' 
								name='email' 
								onChange={handleInput}
								value={account.email}
								placeholder='Enter email used to request invite'
							/>

							<InputField 
								type='password' 
								name='password' 
								onChange={handleInput}
								value={account.password}
								placeholder='Enter Password'
								autocomplete='new-password'
							/>
						</InputFieldContainer>

						<CreateAccountButton width='300px' background={Colors.primary} color={Colors.white}>
							Create Account
						</CreateAccountButton>
					</Form>
				</CreatePasswordWrapper>
				:
				<CreatingAccountAnimationWrapper>
				
				<FadeIn>
			
						<FadeInLoader text="Fetching Your Details" show={doneFetchingUserDetails} />
						
						{
							doneFetchingUserDetails && 
							<FadeInLoader text="Creating Account" show={doneSettingUp} />
						}

						{
							doneSettingUp && 
							<FadeInLoader text="Sprinkling Some Magic" show={doneCreatingUserInfo} />

						}
						{
							doneCreatingUserInfo && 
							<FadeInLoader text="Sending Verification Email" show={doneSendingVerification} />
						}
				</FadeIn>
				</CreatingAccountAnimationWrapper>
			}

	</Container>
	 );
}
 
export default AcceptInvite;

const Container = styled.div`
display: flex;
flex-direction: column;
flex: 1;
justify-content: center;
align-items: center;
`;

const CreatePasswordWrapper = styled.div``;

const Title = styled.h1`
font-size: 50px;
`;

const SubTitle = styled.h3`
`;

const Form = styled.form`
position: relative;
`;

const InputFieldContainer = styled.div`
margin-top: 40px;
`;

const InputField = styled.input`
width: 100%;
margin-top: 8px;
box-sizing: border-box;
border-radius: 10px;
border-color: ${Colors.parlor_grey};
border-width: 1px;
height: 50px;
padding-left: 20px;
`;

const CreateAccountButton = styled(CustomButton)`
display: block;
margin: 50px auto;
font-size: 16px;
`;

const CreatingAccountAnimationWrapper = styled.div``;

const VerifyMessage = styled.h1`
margin-top: 40px;
`;