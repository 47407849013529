import React from 'react';
import styled from 'styled-components';
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import * as threeDotsLoaderData from '../../threeDotsLoader.json'
import * as doneIconData from '../../checkedDone.json';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: threeDotsLoaderData.default,
	rendererSettings: {
	preserveAspectRatio: "xMidYMid slice"
	}
}

const defaultOptions2 = {
	loop: false,
	autoplay: true,
	animationData: doneIconData.default,
	rendererSettings: {
		 preserveAspectRatio: "xMidYMid slice"
	}
};


const FadeInLoader = ({show, text}) => {
	return ( 
		<FadeIn>
		<LoaderWrapper>
			<AnimationTitle>{text}</AnimationTitle>
			{
				show ?
					<Lottie options={defaultOptions2} height={120} width={120} />                  
				:  
					<Lottie options={defaultOptions} height={120} width={120} />  
			}
		</LoaderWrapper>
		</FadeIn>
	 );
}
 
export default FadeInLoader;


const LoaderWrapper = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 width: 400px;
 margin-left: auto;
 margin-right: auto;
`;

const AnimationTitle = styled.h1`
font-size: 18px;
margin: 0;
flex: 1;
`;